<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <h2>
            Selecione a disciplia e atividade da sua obra :
          </h2>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Selecione o cliente :"
              label-for="cliente"
            >
              <v-select
                v-model="cliente"
                label="name"
                placeholder="Cliente"
                :options="opcoescliente"
                @input="buscarContrato()"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Selecione o Contrato :"
              label-for="contrato"
            >
              <v-select
                v-model="contrato"
                label="name"
                placeholder="Contrato"
                :options="opcoescontrato"
                :disabled="!cliente"
                @input="BuscarObra()"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-group
              label="Selecione a Obra :"
              label-for="obra"
            >
              <v-select
                v-model="obra"
                label="name"
                placeholder="Obra"
                :options="opcoesobra"
                :disabled="!contrato || !cliente"
                @input="BuscarDisciplina()"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Selecione a Disciplina :"
              label-for="disciplina"
            >
              <v-select
                v-model="disciplina"
                label="name"
                placeholder="Disciplina"
                :options="opcoesdisciplina"
                :disabled="!contrato || !cliente || !obra"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              block
              @click="SalvarCliente()"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BFormGroup, BRow } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'IndexIncluirDisciplina',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      url: 'admin/disciplina',
      data: {},
      // ---------- Dados V-Model ----------- \\
      id: null,
      cliente: null,
      opcoescliente: [],
      contrato: null,
      opcoescontrato: [],
      obra: null,
      opcoesobra: [],
      disciplina: null,
      opcoesdisciplina: [],
    }
  },
  watch: {
    cliente(newV) {
      if (!newV) this.contrato = null
    },
    contrato(newV) {
      if (!newV) this.obra = null
    },
    obra(newV) {
      if (!newV) this.disciplina = null
    },
  },
  async created() {
    await this.$http.get('admin/clientes_ver').then(cliente => {
      this.opcoescliente = cliente.data
    })
    // this.BuscarDados(this.url)
    // await this.$http.get('admin/cliente').then(cliente => {
    //   const clientesBanco = cliente.data.data
    //   const clientes = []
    //   clientesBanco.forEach(c => {
    //     clientes.push({
    //       value: c.id,
    //       name: c.nome,
    //     })
    //   })
    //   this.opcoescliente = clientes
    // })
    // await this.$http.get('admin/contrato').then(contrato => {
    //   const contratosBanco = contrato.data.data
    //   const contratos = []
    //   contratosBanco.forEach(cont => {
    //     contratos.push({
    //       value: cont.id,
    //       name: cont.numero_contrato,
    //     })
    //   })
    //   this.opcoescontrato = contratos
    // })
    // await this.$http.get('admin/obra').then(obra => {
    //   const obrasBanco = obra.data.data
    //   const obras = []
    //   obrasBanco.forEach(ob => {
    //     obras.push({
    //       value: ob.id,
    //       name: ob.nome,
    //     })
    //   })
    //   this.opcoesobra = obras
    // })
    await this.$http.get('admin/disciplina_categoria').then(disciplinacat => {
      const disciplinaBanco = disciplinacat.data.data
      const disciplinascat = []
      disciplinaBanco.forEach(cat => {
        disciplinascat.push({
          value: cat.id,
          name: cat.nome,
        })
      })
      this.opcoesdisciplina = disciplinascat
    })
  },
  methods: {
    async buscarContrato() {
      if (this.cliente === null) return
      await this.$http.get(`admin/contrato_ver/${this.cliente.value}`).then(contrato => {
        this.opcoescontrato = contrato.data
      })
    },
    async BuscarObra() {
      if (this.contrato === null) return
      await this.$http.get(`admin/obra_ver/${this.contrato.value}`).then(obra => {
        this.opcoesobra = obra.data
      })
    },
    BuscarDados(url = this.url) {
      this.$http.get(url)
        .then(resp => {
          this.data = resp.data
        })
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    LimparCampos() {
      this.id = null
      this.cliente = null
      this.contrato = null
      this.obra = null
      this.disciplina = null
    },
    SalvarCliente() {
      const obj = {
        cliente_id: this.cliente.value,
        contrato_id: this.contrato.value,
        obra_id: this.obra.value,
        disciplina_id: this.disciplina.value,
      }
      if (this.id === null) {
        this.$http.post('admin/disciplina', obj).then(resp => {
          if (resp.data.success) {
            this.Notificacao('success', resp.data.success)
          }
          if (resp.data.error) {
            this.Notificacao('danger', resp.data.error)
          }
          this.LimparCampos()
          this.reloadTable()
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
